/* eslint jsx-a11y/label-has-associated-control: "off" */

import React from 'react';
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStackOverflow, faTwitter, faLinkedin} from '@fortawesome/free-brands-svg-icons';
import favicon from '../images/favicon.ico'
import Helmet from 'react-helmet'

const Header = (props) => {
	return (
		<header className="header">
			<Helmet>
      			<link rel="icon" href={favicon} />
    		</Helmet>
			<div className="container clearfix">
				<img
					style={{ width: '180px', height: '180px' }}
					className={'profile-image img-fluid float-left rounded-circle'}
					src={require(`../images/dean-witcraft.jpg`)}
					alt="Dean"
				/>
				<div className="profile-content float-left">
					<h1 className="name">Dean Witcraft</h1>
					<h2 className="desc">Web Applications and Business Software Development</h2>
					<ul className="social list-inline">
						<li className="list-inline-item">
							<a href="https://twitter.com/DeanWitcraft">
								<FontAwesomeIcon aria-label="Twitter" icon={faTwitter} />
							</a>
						</li>
						<li className="list-inline-item">
							<a href="https://www.linkedin.com/in/deanwitcraft/">
								<FontAwesomeIcon aria-label="LinkedIn" icon={faLinkedin} />
							</a>
						</li>
						<li className="list-inline-item">
							<a href="https://stackoverflow.com/users/1162725/dean">
								<FontAwesomeIcon aria-label="Stack Overflow" icon={faStackOverflow} />
							</a>
						</li>
					</ul>
				</div>
				{props.page === '/' ?
				<div>
					{/* 
				<Link className="btn btn-cta-primary float-right" to="/" target="_blank">
				<i className="fas fa-paper-plane" /> Item One
			</Link>*/}
				<Link className="btn btn-cta-primary float-right" to="/mail-form/" target="_blank">
					<i className="fas fa-paper-plane" /> Contact Me
				</Link>
				</div>
                :
                <Link className="btn btn-cta-primary float-right" to="/" target="_blank">
                <i className="fas fa-paper-plane" /> Home
            </Link>}
			</div>
		</header>
	);
};

export default Header;
