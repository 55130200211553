import React from "react"

import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/styles.css'

const Layout = ({ location, title, children }) => {
  //const rootPath = `${__PATH_PREFIX__}/`
  return (
    <div>
      <main style={{backgroundColor: "rgb(218, 227, 231)"}}>{children}</main>
      <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </footer>
    </div>
  )
}

export default Layout
